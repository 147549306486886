"use client";
import { Icon } from "@components/blocks/icon/Icon";
import {
  ImageBlock,
  ImageBlockType,
} from "@components/blocks/imageBlock/ImageBlock";
import { useEffect, useState } from "react";

export interface IHomeHero {
  showcases: {
    title: string;
    image: {
      imageBlock: ImageBlockType;
    };
  }[];
}

export function HomePageHero({ showcases }: IHomeHero) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === showcases.length - 1 ? 0 : prevIndex + 1
      );
    }, 3500);

    return () => clearInterval(interval);
  }, [showcases.length]);

  return (
    <section className="relative -mb-[3vh] h-[90vh] w-full space-y-0 overflow-hidden bg-primary p-0 ">
      <ImageBlock
        imageBlock={showcases[currentImageIndex].image.imageBlock}
        sizes={["100vw"]}
      />
      <div className="z-1 max-w-[calc(100% - 320px)] absolute left-1/2 top-1/4  flex w-full -translate-x-1/2 -translate-y-1/4 transform items-center justify-center tall:top-[12%] tall:scale-50">
        <Icon iconName="heroLogo" />
      </div>
      <h1 className="z-1 absolute left-1/2 top-[65%]  flex w-1/2 -translate-x-1/2 -translate-y-1/2 transform  flex-col whitespace-pre-line text-center text-sm font-bold uppercase text-primary-foreground ">
        {showcases[currentImageIndex].title}
      </h1>
      <div className="z-1 max-w-[calc(100% - 320px)] absolute bottom-40 flex w-full  animate-bounce items-center justify-center tall:bottom-20">
        <Icon iconName="chevronDown" />
      </div>
    </section>
  );
}
